import { useContext, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

// components
import IndexDropdown from 'components/Dropdowns/IndexDropdown.js'
import { useAuth } from 'context/authContext'

export default function Navbar(props) {
	const { isAuthenticated } = useAuth()
	const [navbarOpen, setNavbarOpen] = useState(false)

	return (
		<>
			<nav className='top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow'>
				<div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
					<div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
						<Link href='/' passHref>
							<a className='text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase'>
								<div className='w-8'>
									<Image
										src={'/img/icon.png'}
										layout='responsive'
										width={'40%'}
										height={'40%'}
									/>
								</div>
							</a>
						</Link>
						<button
							className='cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
							type='button'
							onClick={() => setNavbarOpen(!navbarOpen)}
						>
							<i className='fas fa-bars'></i>
						</button>
					</div>
					<div
						className={
							'lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none' +
							(navbarOpen ? ' block' : ' hidden')
						}
						id='example-navbar-warning'
					>
						<ul className='flex flex-col lg:flex-row list-none mr-auto'>
							<li className='flex items-center'>
								<Link href='/' passHref>
									<a className='hover:text-slate-500 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold'>
										Home
									</a>
								</Link>
							</li>
							<li className='flex items-center'>
								<Link href='/about' passHref>
									<a className='hover:text-slate-500 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold'>
										About us
									</a>
								</Link>
							</li>
							{/* <li className='flex items-center'>
								<IndexDropdown />
							</li> */}
						</ul>
						<ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
							{isAuthenticated ? (
								<>
									<li className='flex items-center'>
										<Link href='admin/dashboard' passHref>
											<a className='bg-slate-700 text-white active:bg-slate-600 font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150 flex items-center text-xs uppercase font-bold'>
												Dashboard
											</a>
										</Link>
									</li>
								</>
							) : (
								<>
									<li className='flex items-center'>
										<Link href='/auth/login' passHref>
											<a
												className='bg-slate-700 text-white active:bg-slate-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
												type='button'
											>
												<i className='fas fa-sign-in-alt'></i> Login
											</a>
										</Link>
									</li>
									{/* <li className='flex items-center'>
										<a
											className='bg-slate-700 text-white active:bg-slate-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
											type='button'
											href='/auth/register'
										>
											<i className='fas fa-user-plus'></i> Register
										</a>
									</li> */}
								</>
							)}
							<li className='flex items-center'>
								<a
									className='hover:text-slate-500 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold'
									href='https://www.facebook.com/xisaabiye'
									target='_blank'
								>
									<i className='text-slate-400 fab fa-facebook text-lg leading-lg ' />
									<span className='lg:hidden inline-block ml-2'>Share</span>
								</a>
							</li>

							<li className='flex items-center'>
								<a
									className='hover:text-slate-500 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold'
									href='https://twitter.com/xisaabiye'
									target='_blank'
								>
									<i className='text-slate-400 fab fa-twitter text-lg leading-lg ' />
									<span className='lg:hidden inline-block ml-2'>Tweet</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}
