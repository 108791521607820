/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import Link from 'next/link'

import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import Hero from 'components/Sections/Hero'
import Footer from 'components/Footers/Footer.js'

import { getPage } from '../graphql/service'

export default function Index({ page }) {
	return (
		<>
			<IndexNavbar fixed />
			{page.components.map((component, index) => {
				if (component.header) {
					return (
						<div key={component.header}>
							<Hero data={component} />
						</div>
					)
				}
			})}

			<Footer />
		</>
	)
}

export async function getStaticProps() {
	const page = await getPage('landing')
	return {
		props: { page },
	}
}
