export default function Hero({ data }) {
	return (
		<section className='header pt-16 flex lg:flex-row flex-col mx-auto items-center h-screen lg:w-8/12 w-full'>
			<div className='container justify-start items-center flex flex-wrap lg:w-6/12 w-full mb-24'>
				<div className='lg:w-10/12 w-full px-4'>
					<div className='pt-32 sm:pt-0 lg:text-left text-center'>
						<h2 className='font-semibold uppercase lg:text-4xl text-3xl text-slate-600'>
							{data.header}
						</h2>
						<p className='mt-4 text-lg leading-relaxed text-slate-500'>
							{data.subHeader}
						</p>
						<div className='mt-12'>
							{data.buttons.map((button) => (
								<a
									href={button.link}
									className={
										button.important
											? 'github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-700 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg'
											: 'get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-400 active:bg-slate-500 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150'
									}
								>
									{button.text}
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
			<img className='lg:w-6/12 w-10/12' src={data.image.url} alt='...' />
		</section>
	)
}
